import React from "react";
import ReactDOM from "react-dom";
import Timer from "./components/Countdown/Timer";
import Optin from "./components/Optin/Optin";

import "./styles.css";

function App() {
  return (
    <div className="App">
      <div className="container" content="width=device-width, initial-scale=1.0">
        <h1>
          Balterr
          <br />
          Coming Soon
        </h1>
        <Timer />
        <Optin />
      </div>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
