import React, { Component } from "react";
import "./Optin.css";

class Optin extends Component {
  modal() {
    const modal = document.getElementById("modal");
    modal.classList.toggle("is_open");
  }

  render() {
    return (
      <div className="c-formContainer">
        <h2>Want to be the first to know when we launch?</h2>
        <div className="c-formContainer">
          <form className="c-form" method="post" id="form" action="https://balterr.us11.list-manage.com/subscribe/post?u=c300ac25b44a29313f410f102&amp;id=c17dfa2722">
            <input className="c-form__input" placeholder="E-mail" name="EMAIL" type="email" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" required />
            <label className="c-form__buttonLabel" htmlFor="checkbox">
              <input className="c-form__button"  type="submit" value="Join" />
            </label>
          </form>
        </div>
      </div>
    );
  }
}

export default Optin;
